import React, { useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useNavigate } from 'react-router-dom';

// Rate Create Component
const RateCreate = () => {
    const [item, setItem] = useState({ 
        id: '',
        typeId: '',
        userId: '',
        comment: '',
        type: '',
        stare: '',
        createAte: '',
    });
    const navigate = useNavigate();

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(baseurl+`api/rate`, item);
        // Redirect to the list or show success message
        navigate(`/admin/rate`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Create Rate</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">typeId</label>
                        <input type="text" name="typeId" value={item.typeId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">userId</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">comment</label>
                        <input type="text" name="comment" value={item.comment} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">type</label>
                        <input type="text" name="type" value={item.type} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">stare</label>
                        <input type="text" name="stare" value={item.stare} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createAte</label>
                        <input type="text" name="createAte" value={item.createAte} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Create</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default RateCreate;
