import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl } from "../../constants";
import { Link } from 'react-router-dom';

// قائمة الفئات
const CategoryList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(baseurl + `api/category?page=${pageNumber}`);
            setData(response.data.items);
            setTotalPages(Math.ceil(response.data.total / 10));
        };
        fetchData();
    }, [pageNumber]);

    const handleDelete = async (id) => {
        if (window.confirm('هل أنت متأكد أنك تريد حذف هذا العنصر؟')) {
            await axios.delete(baseurl + `api/category/` + id);
            setData(data.filter(item => item.id !== id));
        }
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">قائمة الفئات</h3>
            {/* زر إنشاء فئة جديدة */}
            <Link to="/admin/category/create" className="btn btn-primary mb-3">إنشاء فئة</Link>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">معرف</th>
                                    <th scope="col">الاسم (عربي)</th>
                                    <th scope="col">الاسم (إنجليزي)</th>
                                    <th scope="col">الحالة</th>
                                    <th scope="col">تاريخ الإنشاء</th>
                                    <th scope="col">الإجراءات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.id}</td>
                                        <td>{item.nameAr}</td>
                                        <td>{item.nameEng}</td>
                                        <td>{item.status}</td>
                                        <td>{item.createdAt}</td>
                                        <td>
                                            <Link to={`/admin/category/detail/${item.id}`} className="btn btn-primary m-1">عرض</Link>
                                            <Link to={`/admin/category/edit/${item.id}`} className="btn btn-success m-1">تعديل</Link>
                                            <button className="btn btn-danger m-1" onClick={() => handleDelete(item.id)}>حذف</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* التصفح */}
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}>السابق</button>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                    <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => setPageNumber(index + 1)}>{index + 1}</button>
                                    </li>
                                ))}
                                <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}>التالي</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryList;