import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl, uploadImage } from "../../constants"; // Adjust the import based on your project structure

// مكون تعديل سوق
const MarketEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({
        fieldId: '',
        titleAr: '',
        titleEng: '',
        descAr: '',
        descEng: '',
        nameOwner: '',
        password: '',
        addressName: '',
        lat: '',
        lng: '',
        status: '0', // Change to string to match select value
        minimum: null,
        imageLogo: '',
        imageCommercial: '',
        orderCount: 0,
    });

    const [fileLogo, setFileLogo] = useState(null); // To hold the logo file
    const [fileCommercial, setFileCommercial] = useState(null); // To hold the commercial file

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(`${baseurl}api/market/${id}`);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChangeLogo = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileLogo(selectedFile); // Store the logo file in state
        }
    };

    const handleFileChangeCommercial = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileCommercial(selectedFile); // Store the commercial file in state
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Upload the images and get the returned file names
        let uploadedLogo = item.imageLogo; // Keep existing logo if not updated
        let uploadedCommercial = item.imageCommercial; // Keep existing commercial image if not updated
        
        if (fileLogo) {
            uploadedLogo = await uploadImage(fileLogo);
        }
        
        if (fileCommercial) {
            uploadedCommercial = await uploadImage(fileCommercial);
        }

        const payload = {
            ...item,
            imageLogo: uploadedLogo, // Set the uploaded logo image name
            imageCommercial: uploadedCommercial, // Set the uploaded commercial image name
        };

        try {
            await axios.put(`${baseurl}api/market/${id}`, payload);
            navigate(`/admin/market`);
        } catch (error) {
            console.error("Error updating market:", error.response ? error.response.data : error.message);
            alert("Failed to update market: " + (error.response ? error.response.data.message : error.message));
        }
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل سوق</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">رقم المجال</label>
                        <input 
                            type="text" 
                            name="fieldId" 
                            value={item.fieldId} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان بالعربية</label>
                        <input 
                            type="text" 
                            name="titleAr" 
                            value={item.titleAr} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان بالإنجليزية</label>
                        <input 
                            type="text" 
                            name="titleEng" 
                            value={item.titleEng} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف بالعربية</label>
                        <input 
                            type="text" 
                            name="descAr" 
                            value={item.descAr} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف بالإنجليزية</label>
                        <input 
                            type="text" 
                            name="descEng" 
                            value={item.descEng} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">شعار الصورة</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChangeLogo} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">صورة تجارية</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChangeCommercial} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">اسم المالك</label>
                        <input 
                            type="text" 
                            name="nameOwner" 
                            value={item.nameOwner} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">كلمة السر</label>
                        <input 
                            type="text" 
                            name="password" 
                            value={item.password} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان</label>
                        <input 
                            type="text" 
                            name="addressName" 
                            value={item.addressName} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط العرض</label>
                        <input 
                            type="number" 
                            name="lat" 
                            value={item.lat} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط الطول</label>
                        <input 
                            type="number" 
                            name="lng" 
                            value={item.lng} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <select 
                            name="status" 
                            value={item.status} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required
                        >
                            <option value="1">مفعل</option>
                            <option value="0">غير مفعل</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحد الأدنى</label>
                        <input 
                            type="number" 
                            name="minimum" 
                            value={item.minimum || ''} 
                            onChange={handleChanges} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MarketEdit;