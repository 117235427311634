import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl } from "../../constants";
import { Link } from 'react-router-dom';

// قائمة المندوبين
const MarketerList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const currentUserId = localStorage.getItem('id'); // Get the current user ID
    const currentUserRole = localStorage.getItem('role'); // Get the current user role
    useEffect(() => {
        const fetchData = async () => {
            try {
                let response;
                
                // Check the user's role and call the appropriate API
                if (currentUserRole === 'supervisor') {
                    response = await axios.get(`${baseurl}api/user/supervisor-marketers?supervisor=${currentUserId}`, {
                        params: { userId: currentUserId, page: pageNumber }
                    });
                } else if (currentUserRole === 'admin') {
                    response = await axios.get(`${baseurl}api/user`, {
                        params: { page: pageNumber, role: 'marketer' }
                    });
                } else {
                    console.warn("Current user does not have permission to view this data.");
                    return;
                }

                setData(response.data.items);
                setTotalPages(Math.ceil(response.data.total / 10));
            } catch (error) {
                console.error("Error fetching marketers:", error);
            }
        };

        fetchData();
    }, [pageNumber, currentUserRole, currentUserId]);

    const handleDelete = async (id) => {
        if (window.confirm('هل أنت متأكد أنك تريد حذف هذا العنصر؟')) {
            await axios.delete(`${baseurl}api/user/${id}`);
            setData(data.filter(item => item.id !== id));
        }
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">قائمة المندوبين</h3>
            {/* زر إنشاء مندوب جديد */}
            <Link to="/admin/marketer/create" className="btn btn-primary mb-3">إنشاء مندوب</Link>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">الاسم الكامل</th>
                                    <th scope="col">البريد الإلكتروني</th>
                                    <th scope="col">الحالة</th>
                                    <th scope="col">المحفظة</th>
                                    <th scope="col">صورة</th> {/* New column for image */}
                                    <th scope="col">تاريخ الإنشاء</th>
                                    <th scope="col">الإجراءات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.fullName}</td>
                                        <td>{item.email}</td>
                                        <td>{item.status}</td>
                                        <td>{item.wallet}</td>
                                        <td>
                                            {item.profileImage ? (
                                                <img
                                                    src={`${baseurl}uploads/${item.profileImage}`}
                                                    alt={`${item.fullName}'s profile`}
                                                    className='rounded'
                                                    style={{ width: '50px', height: '50px' }}
                                                />
                                            ) : (
                                                <span>No Image</span>
                                            )}
                                        </td>
                                        <td>{item.createdAt}</td>
                                        <td>
                                            <Link to={`/admin/marketer/detail/${item.id}`} className="btn btn-primary m-1">عرض</Link>
                                            <Link to={`/admin/marketer/edit/${item.id}`} className="btn btn-success m-1">تعديل</Link>
                                            <button className="btn btn-danger m-1" onClick={() => handleDelete(item.id)}>حذف</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* Pagination */}
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}>السابق</button>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                    <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => setPageNumber(index + 1)}>{index + 1}</button>
                                    </li>
                                ))}
                                <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}>التالي</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketerList;