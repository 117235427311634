import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// Rate Detail Component
const RateDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/rate/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Rate Details</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">id</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">typeId</label>
                        <input type="text" value={item.typeId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">userId</label>
                        <input type="text" value={item.userId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">comment</label>
                        <input type="text" value={item.comment} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">type</label>
                        <input type="text" value={item.type} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">stare</label>
                        <input type="text" value={item.stare} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">createAte</label>
                        <input type="text" value={item.createAte} disabled className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RateDetail;
