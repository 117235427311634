import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl, uploadImage } from "../../constants"; 
import { useNavigate } from 'react-router-dom';

// مكون إنشاء سوق
const MarketCreate = () => {
    const [item, setItem] = useState({ 
        fieldId: '',
        titleAr: '',
        titleEng: '',
        descAr: '',
        descEng: '',
        nameOwner: '',
        password: '',
        addressName: '',
        lat: '',
        lng: '',
        status: '0', // Change to string for consistent dropdown value
        minimum: null,
        orderCount: 0,
        marketer: '', // New field for marketer
    });

    const [fileLogo, setFileLogo] = useState(null); 
    const [fileCommercial, setFileCommercial] = useState(null); 
    const [fields, setFields] = useState([]); 
    const [marketers, setMarketers] = useState([]); 
    const navigate = useNavigate();

    // Fetch fields and marketers from API
    useEffect(() => {
        const fetchFields = async () => {
            try {
                const response = await axios.get(`${baseurl}api/field`);
                setFields(response.data.items); 
            } catch (error) {
                console.error("Error fetching fields:", error);
            }
        };

        const fetchMarketers = async () => {
            try {
                const response = await axios.get(`${baseurl}api/user?role=marketer`);
                setMarketers(response.data.items); 
            } catch (error) {
                console.error("Error fetching marketers:", error);
            }
        };

        fetchFields();
        fetchMarketers();
    }, []);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChangeLogo = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileLogo(selectedFile); 
        }
    };

    const handleFileChangeCommercial = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileCommercial(selectedFile); 
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let uploadedLogo = '';
        let uploadedCommercial = '';
        
        if (fileLogo) {
            uploadedLogo = await uploadImage(fileLogo);
        }
        
        if (fileCommercial) {
            uploadedCommercial = await uploadImage(fileCommercial);
        }

        const payload = {
            ...item,
            imageLogo: uploadedLogo, 
            imageCommercial: uploadedCommercial, 
            createdAt: new Date().toISOString(), 
        };

        try {
            const response = await axios.post(`${baseurl}api/market`, payload);
            console.log("Market created successfully:", response.data);

            // Update marketer's wallet
            await updateMarketerWallet(item.marketer);

            navigate(`/admin/market`);
        } catch (error) {
            console.error("Error creating market:", error.response ? error.response.data : error.message);
            alert("Failed to create market: " + (error.response ? error.response.data.message : error.message));
        }
    };

    const updateMarketerWallet = async (marketerId) => {
        try {
            const response = await axios.get(`${baseurl}api/user/${marketerId}`);
            const marketer = response.data;

            // Increase the wallet by 20
            marketer.wallet += 20;

            await axios.put(`${baseurl}api/user/${marketerId}`, marketer);
            console.log("Marketer's wallet updated successfully");
        } catch (error) {
            console.error("Error updating marketer's wallet:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">إنشاء سوق</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">رقم المجال</label>
                        <select name="fieldId" value={item.fieldId} onChange={handleChanges} className="form-control" required>
                            <option value="">اختر مجال</option>
                            {fields.map(field => (
                                <option key={field.id} value={field.id}>
                                    {field.nameAr}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">اسم المندوب</label>
                        <select name="marketer" value={item.marketer} onChange={handleChanges} className="form-control" required>
                            <option value="">اختر مندوب</option>
                            {marketers.map(marketer => (
                                <option key={marketer.id} value={marketer.id}>
                                    {marketer.fullName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان بالعربية</label>
                        <input 
                            type="text" 
                            name="titleAr" 
                            value={item.titleAr} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان بالإنجليزية</label>
                        <input 
                            type="text" 
                            name="titleEng" 
                            value={item.titleEng} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف بالعربية</label>
                        <input 
                            type="text" 
                            name="descAr" 
                            value={item.descAr} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف بالإنجليزية</label>
                        <input 
                            type="text" 
                            name="descEng" 
                            value={item.descEng} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">شعار الصورة</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChangeLogo} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">صورة تجارية</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChangeCommercial} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">اسم المالك</label>
                        <input 
                            type="text" 
                            name="nameOwner" 
                            value={item.nameOwner} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">كلمة السر</label>
                        <input 
                            type="password" 
                            name="password" 
                            value={item.password} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان</label>
                        <input 
                            type="text" 
                            name="addressName" 
                            value={item.addressName} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط العرض</label>
                        <input 
                            type="number" 
                            name="lat" 
                            value={item.lat} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط الطول</label>
                        <input 
                            type="number" 
                            name="lng" 
                            value={item.lng} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <select name="status" value={item.status} onChange={handleChanges} className="form-control" required>
                            <option value="1">مفعل</option>
                            <option value="0">غير مفعل</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحد الأدنى</label>
                        <input 
                            type="number" 
                            name="minimum" 
                            value={item.minimum || ''} 
                            onChange={handleChanges} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">إنشاء</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MarketCreate;