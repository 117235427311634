import React, { useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useNavigate } from 'react-router-dom';

// OrderItem Create Component
const OrderItemCreate = () => {
    const [item, setItem] = useState({ 
        id: '',
        productId: '',
        quantity: '',
        options: '',
        orderId: '',
        cost: '',
        userId: '',
        createdAt: '',
    });
    const navigate = useNavigate();

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(baseurl+`api/orderitem`, item);
        // Redirect to the list or show success message
        navigate(`/admin/orderitem`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Create OrderItem</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">productId</label>
                        <input type="text" name="productId" value={item.productId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">quantity</label>
                        <input type="text" name="quantity" value={item.quantity} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">options</label>
                        <input type="text" name="options" value={item.options} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">orderId</label>
                        <input type="text" name="orderId" value={item.orderId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">cost</label>
                        <input type="text" name="cost" value={item.cost} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">userId</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createdAt</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Create</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default OrderItemCreate;
