import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// تفاصيل السلة المكون
const CartDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/cart/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل السلة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">المعرف</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">معرف المنتج</label>
                            <input type="text" value={item.productId} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">معرف السوق</label>
                            <input type="text" value={item.marketId} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">الكمية</label>
                            <input type="text" value={item.quantity} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">الحالة</label>
                            <input type="text" value={item.status} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">معرف الطلب</label>
                            <input type="text" value={item.orderId} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">التكلفة</label>
                            <input type="text" value={item.cost} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">معرف المستخدم</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">معرفات الخيارات</label>
                            <input type="text" value={item.optionsIds} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">ملاحظات</label>
                            <input type="text" value={item.nots} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">تاريخ الإنشاء</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CartDetail;