import React, { useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useNavigate } from 'react-router-dom';

// Offer Create Component
const OfferCreate = () => {
    const [item, setItem] = useState({ 
        id: '',
        image: '',
        icon: '',
        status: '',
        type: '',
        descAr: '',
        descEng: '',
        createdAt: '',
    });
    const navigate = useNavigate();

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(baseurl+`api/offer`, item);
        // Redirect to the list or show success message
        navigate(`/admin/offer`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Create Offer</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">image</label>
                        <input type="text" name="image" value={item.image} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">icon</label>
                        <input type="text" name="icon" value={item.icon} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">status</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">type</label>
                        <input type="text" name="type" value={item.type} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">descAr</label>
                        <input type="text" name="descAr" value={item.descAr} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">descEng</label>
                        <input type="text" name="descEng" value={item.descEng} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createdAt</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Create</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default OfferCreate;
