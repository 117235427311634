import React from 'react';

// RoleCheck component
const RoleCheck = ({ roles, children, hideIfNoRole = false }) => {
    // Get the current user's role from local storage
    const currentUserRole = localStorage.getItem('role');

    // Check if the current user's role matches any of the required roles
    const hasRole = roles.includes(currentUserRole);

    // If hideIfNoRole is true, return null if the role doesn't match
    if (hideIfNoRole && !hasRole) {
        return null;
    }

    // Return children with disabled attribute if roles don't match and hideIfNoRole is false
    return (
        <div>
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { disabled: !hasRole });
                }
                return child;
            })}
        </div>
    );
};

export default RoleCheck;