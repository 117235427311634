import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// Driver Detail Component
const DriverDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/driver/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Driver Details</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">id</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">userId</label>
                        <input type="text" value={item.userId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">marketId</label>
                        <input type="text" value={item.marketId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">addressName</label>
                        <input type="text" value={item.addressName} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">lat</label>
                        <input type="text" value={item.lat} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">lng</label>
                        <input type="text" value={item.lng} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">profilleImage</label>
                        <input type="text" value={item.profilleImage} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">imageId</label>
                        <input type="text" value={item.imageId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">drivingLicense</label>
                        <input type="text" value={item.drivingLicense} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">vehicleLicense</label>
                        <input type="text" value={item.vehicleLicense} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">status</label>
                        <input type="text" value={item.status} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">area</label>
                        <input type="text" value={item.area} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">rating</label>
                        <input type="text" value={item.rating} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">createAte</label>
                        <input type="text" value={item.createAte} disabled className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DriverDetail;
