import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// تفاصيل التنبيه المكون
const AlertDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/alert/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل التنبيه</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">المعرف:</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">معرف المستخدم:</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">العنوان (عربي):</label>
                            <input type="text" value={item.titleAr} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">العنوان (إنجليزي):</label>
                            <input type="text" value={item.titleEng} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">الوصف (عربي):</label>
                            <input type="text" value={item.descAr} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">الوصف (إنجليزي):</label>
                            <input type="text" value={item.descEng} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">الصفحة:</label>
                            <input type="text" value={item.page} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">النوع:</label>
                            <input type="text" value={item.type} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">معرف الصفحة:</label>
                            <input type="text" value={item.pageId} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">تاريخ الإنشاء:</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AlertDetail;