import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl }from "../../constants";

// Address Edit Component
const AddressEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ id: '', userId: '', description: '', name: '', lat: '', lng: '', defaultAddress: '', createdAt: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/address/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/address/`+id, item);
        // Redirect or show success message
        navigate(`/admin/address`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Edit Address</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">userId</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">description</label>
                        <input type="text" name="description" value={item.description} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">name</label>
                        <input type="text" name="name" value={item.name} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">lat</label>
                        <input type="text" name="lat" value={item.lat} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">lng</label>
                        <input type="text" name="lng" value={item.lng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">defaultAddress</label>
                        <input type="text" name="defaultAddress" value={item.defaultAddress} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createdAt</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AddressEdit;
