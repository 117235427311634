import React, { useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useNavigate } from 'react-router-dom';

// إنشاء عنوان المكون
const AddressCreate = () => {
    const [item, setItem] = useState({ 
        id: '',
        userId: '',
        description: '',
        name: '',
        lat: '',
        lng: '',
        defaultAddress: '',
        createdAt: '',
    });
    const navigate = useNavigate();

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(baseurl+`api/address`, item);
        // إعادة التوجيه إلى القائمة أو عرض رسالة النجاح
        navigate(`/admin/address`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">إنشاء عنوان</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label"> المعرف</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المستخدم</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف</label>
                        <input type="text" name="description" value={item.description} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الاسم</label>
                        <input type="text" name="name" value={item.name} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط العرض</label>
                        <input type="text" name="lat" value={item.lat} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط الطول</label>
                        <input type="text" name="lng" value={item.lng} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">عنوان افتراضي</label>
                        <input type="text" name="defaultAddress" value={item.defaultAddress} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ الإنشاء</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">إنشاء</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AddressCreate;
