import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// Market Detail Component
const MarketDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/market/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Market Details</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">id</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">fieldId</label>
                        <input type="text" value={item.fieldId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">offerId</label>
                        <input type="text" value={item.offerId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">titleAr</label>
                        <input type="text" value={item.titleAr} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">titleEng</label>
                        <input type="text" value={item.titleEng} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">descAr</label>
                        <input type="text" value={item.descAr} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">descEng</label>
                        <input type="text" value={item.descEng} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">userId</label>
                        <input type="text" value={item.userId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">imageLogo</label>
                        <input type="text" value={item.imageLogo} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">imageCommercial</label>
                        <input type="text" value={item.imageCommercial} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">nameOwner</label>
                        <input type="text" value={item.nameOwner} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">password</label>
                        <input type="text" value={item.password} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">addressName</label>
                        <input type="text" value={item.addressName} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">lat</label>
                        <input type="text" value={item.lat} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">lng</label>
                        <input type="text" value={item.lng} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">rate</label>
                        <input type="text" value={item.rate} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">status</label>
                        <input type="text" value={item.status} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">discount</label>
                        <input type="text" value={item.discount} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">distance</label>
                        <input type="text" value={item.distance} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">walet</label>
                        <input type="text" value={item.walet} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">minimum</label>
                        <input type="text" value={item.minimum} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">dlivaryCost</label>
                        <input type="text" value={item.dlivaryCost} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">timeDelvary</label>
                        <input type="text" value={item.timeDelvary} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">orderCount</label>
                        <input type="text" value={item.orderCount} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">createdAt</label>
                        <input type="text" value={item.createdAt} disabled className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MarketDetail;
