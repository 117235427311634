import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl } from "../../constants";
import { useParams } from 'react-router-dom';

// User Detail Component
const UserDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/user/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل المستخدم</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-6 mb-3">
                            <label className="form-label">الدور</label>
                            <input type="text" value={item.role} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الاسم الكامل</label>
                            <input type="text" value={item.fullName} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">اسم المستخدم</label>
                            <input type="text" value={item.userName} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">البريد الإلكتروني</label>
                            <input type="text" value={item.email} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">صورة الملف الشخصي</label>
                            <input type="text" value={item.profileImage} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المدينة</label>
                            <input type="text" value={item.city} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الحالة</label>
                            <input type="text" value={item.status} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المحفظة</label>
                            <input type="text" value={item.wallet} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserDetail;