import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// تفاصيل العنوان المكون
const AddressDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/address/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل العنوان</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">المعرف:</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">معرف المستخدم:</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">الوصف:</label>
                            <input type="text" value={item.description} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">الاسم:</label>
                            <input type="text" value={item.name} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">خط العرض:</label>
                            <input type="text" value={item.lat} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">خط الطول:</label>
                            <input type="text" value={item.lng} disabled className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <label className="form-label">عنوان افتراضي:</label>
                            <input type="text" value={item.defaultAddress} disabled className="form-control" />
                        </div>
                        <div className="col-6">
                            <label className="form-label">تاريخ الإنشاء:</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddressDetail;