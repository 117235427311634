import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl }from "../../constants";

// Alert Edit Component
const AlertEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ id: '', userId: '', titleAr: '', titleEng: '', descAr: '', descEng: '', page: '', type: '', pageId: '', createdAt: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/alert/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/alert/`+id, item);
        // Redirect or show success message
        navigate(`/admin/alert`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Edit Alert</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">userId</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">titleAr</label>
                        <input type="text" name="titleAr" value={item.titleAr} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">titleEng</label>
                        <input type="text" name="titleEng" value={item.titleEng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">descAr</label>
                        <input type="text" name="descAr" value={item.descAr} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">descEng</label>
                        <input type="text" name="descEng" value={item.descEng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">page</label>
                        <input type="text" name="page" value={item.page} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">type</label>
                        <input type="text" name="type" value={item.type} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">pageId</label>
                        <input type="text" name="pageId" value={item.pageId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createdAt</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AlertEdit;
