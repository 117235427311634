import React, { useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useNavigate } from 'react-router-dom';

// Driver Create Component
const DriverCreate = () => {
    const [item, setItem] = useState({ 
        id: '',
        userId: '',
        marketId: '',
        addressName: '',
        lat: '',
        lng: '',
        profilleImage: '',
        imageId: '',
        drivingLicense: '',
        vehicleLicense: '',
        status: '',
        area: '',
        rating: '',
        createAte: '',
    });
    const navigate = useNavigate();

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(baseurl+`api/driver`, item);
        // Redirect to the list or show success message
        navigate(`/admin/driver`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Create Driver</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">userId</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">marketId</label>
                        <input type="text" name="marketId" value={item.marketId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">addressName</label>
                        <input type="text" name="addressName" value={item.addressName} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">lat</label>
                        <input type="text" name="lat" value={item.lat} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">lng</label>
                        <input type="text" name="lng" value={item.lng} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">profilleImage</label>
                        <input type="text" name="profilleImage" value={item.profilleImage} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">imageId</label>
                        <input type="text" name="imageId" value={item.imageId} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">drivingLicense</label>
                        <input type="text" name="drivingLicense" value={item.drivingLicense} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">vehicleLicense</label>
                        <input type="text" name="vehicleLicense" value={item.vehicleLicense} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">status</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">area</label>
                        <input type="text" name="area" value={item.area} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">rating</label>
                        <input type="text" name="rating" value={item.rating} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createAte</label>
                        <input type="text" name="createAte" value={item.createAte} onChange={handleChanges} className="form-control" required />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">Create</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default DriverCreate;
