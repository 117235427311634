import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl }from "../../constants";

// Product Edit Component
const ProductEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ id: '', nameAr: '', nameEng: '', imageUrl: '', descAr: '', descEng: '', isCart: '', isFav: '', quantity: '', price: '', fieldId: '', categoryId: '', marketId: '', offerId: '', status: '', createdAt: '', rate: '', discount: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/product/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/product/`+id, item);
        // Redirect or show success message
        navigate(`/admin/product`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Edit Product</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">nameAr</label>
                        <input type="text" name="nameAr" value={item.nameAr} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">nameEng</label>
                        <input type="text" name="nameEng" value={item.nameEng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">imageUrl</label>
                        <input type="text" name="imageUrl" value={item.imageUrl} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">descAr</label>
                        <input type="text" name="descAr" value={item.descAr} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">descEng</label>
                        <input type="text" name="descEng" value={item.descEng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">isCart</label>
                        <input type="text" name="isCart" value={item.isCart} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">isFav</label>
                        <input type="text" name="isFav" value={item.isFav} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">quantity</label>
                        <input type="text" name="quantity" value={item.quantity} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">price</label>
                        <input type="text" name="price" value={item.price} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">fieldId</label>
                        <input type="text" name="fieldId" value={item.fieldId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">categoryId</label>
                        <input type="text" name="categoryId" value={item.categoryId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">marketId</label>
                        <input type="text" name="marketId" value={item.marketId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">offerId</label>
                        <input type="text" name="offerId" value={item.offerId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">status</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createdAt</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">rate</label>
                        <input type="text" name="rate" value={item.rate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">discount</label>
                        <input type="text" name="discount" value={item.discount} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default ProductEdit;
