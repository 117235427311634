import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// Order Detail Component
const OrderDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/order/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Order Details</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">id</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">marketId</label>
                        <input type="text" value={item.marketId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">status</label>
                        <input type="text" value={item.status} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">addressId</label>
                        <input type="text" value={item.addressId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">userId</label>
                        <input type="text" value={item.userId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">totalCost</label>
                        <input type="text" value={item.totalCost} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">tax</label>
                        <input type="text" value={item.tax} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">deliveryCost</label>
                        <input type="text" value={item.deliveryCost} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">productsCost</label>
                        <input type="text" value={item.productsCost} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">driverId</label>
                        <input type="text" value={item.driverId} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">payment</label>
                        <input type="text" value={item.payment} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">notes</label>
                        <input type="text" value={item.notes} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">rate</label>
                        <input type="text" value={item.rate} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">createdAt</label>
                        <input type="text" value={item.createdAt} disabled className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OrderDetail;
