import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl, uploadImage } from "../../constants"; // Adjust the import based on your project structure

// مكون تعديل المندوب
const MarketerEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({
        role: 'marketer',
        fullName: '',
        userName: '',
        email: '',
        profileImage: '',
        city: '',
        wallet: null,
        status: 'مفعل', // Default status
        supervisor: '', // Field for supervisor
    });

    const [file, setFile] = useState(null); // To hold the selected file
    const [supervisors, setSupervisors] = useState([]); // State to hold supervisors

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/user/` + id);
            setItem(response.data);
        };

        const fetchSupervisors = async () => {
            try {
                const response = await axios.get(baseurl + `api/user?page=1&limit=10&role=supervisor`);
                setSupervisors(response.data.items); // Assuming the API returns the items in this format
            } catch (error) {
                console.error("Error fetching supervisors:", error.response ? error.response.data : error.message);
            }
        };

        fetchItem();
        fetchSupervisors();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile); // Store the file in state
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Upload the image and get the returned file name
        let uploadedImage = item.profileImage; // Keep existing image if no new upload
        if (file) {
            uploadedImage = await uploadImage(file);
        }

        const payload = {
            ...item,
            profileImage: uploadedImage, // Set the uploaded image name
        };

        try {
            await axios.put(baseurl + `api/user/` + id, payload);
            console.log("User updated successfully");
            navigate(`/admin/marketer`);
        } catch (error) {
            console.error("Error updating user:", error.response ? error.response.data : error.message);
            alert("Failed to update user: " + (error.response ? error.response.data.message : error.message));
        }
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل المندوب</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">الدور</label>
                        <input 
                            type="text" 
                            name="role" 
                            value={item.role} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الاسم الكامل</label>
                        <input 
                            type="text" 
                            name="fullName" 
                            value={item.fullName} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">اسم المندوب</label>
                        <input 
                            type="text" 
                            name="userName" 
                            value={item.userName} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">البريد الإلكتروني</label>
                        <input 
                            type="email" 
                            name="email" 
                            value={item.email} 
                            onChange={handleChanges} 
                            className="form-control" 
                            required 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">صورة الملف الشخصي</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={handleFileChange} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المدينة</label>
                        <input 
                            type="text" 
                            name="city" 
                            value={item.city} 
                            onChange={handleChanges} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المحفظة</label>
                        <input 
                            type="number" 
                            name="wallet" 
                            value={item.wallet} 
                            onChange={handleChanges} 
                            className="form-control" 
                        />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <select 
                            name="status" 
                            value={item.status} 
                            onChange={handleChanges} 
                            className="form-select" 
                            required
                        >
                            <option value="مفعل">مفعل</option>
                            <option value="غير مفعل">غير مفعل</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <label className="form-label">المشرف</label>
                        <select 
                            name="supervisor" 
                            value={item.supervisor} 
                            onChange={handleChanges} 
                            className="form-select" 
                            required
                        >
                            <option value="">اختر مشرف</option>
                            {supervisors.map(supervisor => (
                                <option key={supervisor.id} value={supervisor.id}>
                                    {supervisor.fullName} {/* Adjust based on the actual fields returned */}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MarketerEdit;