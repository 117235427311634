
import './App.css';
import Dashboard from './Components/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddressCreate from './Pages/Address/create';
import AddressList from './Pages/Address/index';
import AddressEdit from './Pages/Address/edit';
import AddressDetail from './Pages/Address/detail';

import AlertCreate from './Pages/Alert/create';
import AlertList from './Pages/Alert/index';
import AlertEdit from './Pages/Alert/edit';
import AlertDetail from './Pages/Alert/detail';

import AppConfigCreate from './Pages/AppConfig/create';
import AppConfigList from './Pages/AppConfig/index';
import AppConfigEdit from './Pages/AppConfig/edit';
import AppConfigDetail from './Pages/AppConfig/detail';

import CartCreate from './Pages/Cart/create';
import CartList from './Pages/Cart/index';
import CartEdit from './Pages/Cart/edit';
import CartDetail from './Pages/Cart/detail';

import CategoryCreate from './Pages/Category/create';
import CategoryList from './Pages/Category/index';
import CategoryEdit from './Pages/Category/edit';
import CategoryDetail from './Pages/Category/detail';

import DriverCreate from './Pages/Driver/create';
import DriverList from './Pages/Driver/index';
import DriverEdit from './Pages/Driver/edit';
import DriverDetail from './Pages/Driver/detail';

import FavoriteCreate from './Pages/Favorite/create';
import FavoriteList from './Pages/Favorite/index';
import FavoriteEdit from './Pages/Favorite/edit';
import FavoriteDetail from './Pages/Favorite/detail';

import FieldCreate from './Pages/Field/create';
import FieldList from './Pages/Field/index';
import FieldEdit from './Pages/Field/edit';
import FieldDetail from './Pages/Field/detail';

import GroupOptionsCreate from './Pages/GroupOptions/create';
import GroupOptionsList from './Pages/GroupOptions/index';
import GroupOptionsEdit from './Pages/GroupOptions/edit';
import GroupOptionsDetail from './Pages/GroupOptions/detail';

import MarketCreate from './Pages/Market/create';
import MarketList from './Pages/Market/index';
import MarketEdit from './Pages/Market/edit';
import MarketDetail from './Pages/Market/detail';

import OfferCreate from './Pages/Offer/create';
import OfferList from './Pages/Offer/index';
import OfferEdit from './Pages/Offer/edit';
import OfferDetail from './Pages/Offer/detail';

import OrderList from './Pages/Order/index';
import OrderEdit from './Pages/Order/edit';
import OrderDetail from './Pages/Order/detail';

import OrderItemCreate from './Pages/OrderItem/create';
import OrderItemList from './Pages/OrderItem/index';
import OrderItemEdit from './Pages/OrderItem/edit';
import OrderItemDetail from './Pages/OrderItem/detail';

import ProductCreate from './Pages/Product/create';
import ProductList from './Pages/Product/index';
import ProductEdit from './Pages/Product/edit';
import ProductDetail from './Pages/Product/detail';

import RateCreate from './Pages/Rate/create';
import RateList from './Pages/Rate/index';
import RateEdit from './Pages/Rate/edit';
import RateDetail from './Pages/Rate/detail';

import TransactionCreate from './Pages/Transaction/create';
import TransactionList from './Pages/Transaction/index';
import TransactionEdit from './Pages/Transaction/edit';
import TransactionDetail from './Pages/Transaction/detail';

import UserCreate from './Pages/User/create';
import UserList from './Pages/User/index';
import UserEdit from './Pages/User/edit';
import UserDetail from './Pages/User/detail';
import OrderCreate from './Pages/Order/create';
import SuperVisorCreate from './Pages/SuperVisor/create';
import SuperVisorList from './Pages/SuperVisor';
import SuperVisorEdit from './Pages/SuperVisor/edit';
import SuperVisorDetail from './Pages/SuperVisor/detail';
import MarketerCreate from './Pages/marketer/create';
import MarketerList from './Pages/marketer';
import MarketerEdit from './Pages/marketer/edit';
import MarketerDetail from './Pages/marketer/detail';




function App() {
  return (
    <div className="App">
      <Router >
        <Routes >
          <Route path="" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<Dashboard />}>
            <Route path="" element={<UserList />} />
            <Route path="home" element={<Home />} />



//Address

            <Route path="address/create" element={<AddressCreate />} />
            <Route path="address" element={<AddressList />} />
            <Route path="address/edit/:id" element={<AddressEdit />} />
            <Route path="address/detail/:id" element={<AddressDetail />} />
//Alert

            <Route path="alert/create" element={<AlertCreate />} />
            <Route path="alert" element={<AlertList />} />
            <Route path="alert/edit/:id" element={<AlertEdit />} />
            <Route path="alert/detail/:id" element={<AlertDetail />} />
//AppConfig

            <Route path="appconfig/create" element={<AppConfigCreate />} />
            <Route path="appconfig" element={<AppConfigList />} />
            <Route path="appconfig/edit/:id" element={<AppConfigEdit />} />
            <Route path="appconfig/detail/:id" element={<AppConfigDetail />} />
//Cart

            <Route path="cart/create" element={<CartCreate />} />
            <Route path="cart" element={<CartList />} />
            <Route path="cart/edit/:id" element={<CartEdit />} />
            <Route path="cart/detail/:id" element={<CartDetail />} />
//Category

            <Route path="category/create" element={<CategoryCreate />} />
            <Route path="category" element={<CategoryList />} />
            <Route path="category/edit/:id" element={<CategoryEdit />} />
            <Route path="category/detail/:id" element={<CategoryDetail />} />
//Driver

            <Route path="driver/create" element={<DriverCreate />} />
            <Route path="driver" element={<DriverList />} />
            <Route path="driver/edit/:id" element={<DriverEdit />} />
            <Route path="driver/detail/:id" element={<DriverDetail />} />
//Favorite

            <Route path="favorite/create" element={<FavoriteCreate />} />
            <Route path="favorite" element={<FavoriteList />} />
            <Route path="favorite/edit/:id" element={<FavoriteEdit />} />
            <Route path="favorite/detail/:id" element={<FavoriteDetail />} />
//Field

            <Route path="field/create" element={<FieldCreate />} />
            <Route path="field" element={<FieldList />} />
            <Route path="field/edit/:id" element={<FieldEdit />} />
            <Route path="field/detail/:id" element={<FieldDetail />} />
//GroupOptions

            <Route path="groupoptions/create" element={<GroupOptionsCreate />} />
            <Route path="groupoptions" element={<GroupOptionsList />} />
            <Route path="groupoptions/edit/:id" element={<GroupOptionsEdit />} />
            <Route path="groupoptions/detail/:id" element={<GroupOptionsDetail />} />
//Market

            <Route path="market/create" element={<MarketCreate />} />
            <Route path="market" element={<MarketList />} />
            <Route path="market/edit/:id" element={<MarketEdit />} />
            <Route path="market/detail/:id" element={<MarketDetail />} />
//Offer

            <Route path="offer/create" element={<OfferCreate />} />
            <Route path="offer" element={<OfferList />} />
            <Route path="offer/edit/:id" element={<OfferEdit />} />
            <Route path="offer/detail/:id" element={<OfferDetail />} />
//Order

            <Route path="order/create" element={<OrderCreate />} />
            <Route path="order" element={<OrderList />} />
            <Route path="order/edit/:id" element={<OrderEdit />} />
            <Route path="order/detail/:id" element={<OrderDetail />} />
//OrderItem

            <Route path="orderitem/create" element={<OrderItemCreate />} />
            <Route path="orderitem" element={<OrderItemList />} />
            <Route path="orderitem/edit/:id" element={<OrderItemEdit />} />
            <Route path="orderitem/detail/:id" element={<OrderItemDetail />} />
//Product

            <Route path="product/create" element={<ProductCreate />} />
            <Route path="product" element={<ProductList />} />
            <Route path="product/edit/:id" element={<ProductEdit />} />
            <Route path="product/detail/:id" element={<ProductDetail />} />
//Rate

            <Route path="rate/create" element={<RateCreate />} />
            <Route path="rate" element={<RateList />} />
            <Route path="rate/edit/:id" element={<RateEdit />} />
            <Route path="rate/detail/:id" element={<RateDetail />} />
//Transaction

            <Route path="transaction/create" element={<TransactionCreate />} />
            <Route path="transaction" element={<TransactionList />} />
            <Route path="transaction/edit/:id" element={<TransactionEdit />} />
            <Route path="transaction/detail/:id" element={<TransactionDetail />} />
//User

            <Route path="user/create" element={<UserCreate />} />
            <Route path="user" element={<UserList />} />
            <Route path="user/edit/:id" element={<UserEdit />} />
            <Route path="user/detail/:id" element={<UserDetail />} />
//SuperVisor

            <Route path="supervisor/create" element={<SuperVisorCreate />} />
            <Route path="supervisor" element={<SuperVisorList />} />
            <Route path="supervisor/edit/:id" element={<SuperVisorEdit />} />
            <Route path="supervisor/detail/:id" element={<SuperVisorDetail />} />

  //Marketer

            <Route path="marketer/create" element={<MarketerCreate />} />
            <Route path="marketer" element={<MarketerList />} />
            <Route path="marketer/edit/:id" element={<MarketerEdit />} />
            <Route path="marketer/detail/:id" element={<MarketerDetail />} />


          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

