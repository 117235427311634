import loadjs from 'loadjs';
import React from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ExternalScripts from './ExternalScripts';
import RoleCheck from './RoleCheck';

export default function Sidebar() {
  const navigateTo = useNavigate();

  return (
    <aside className="sidebar-wrapper" data-simplebar="true">
      <ExternalScripts />

      <div className="sidebar-header">
        <div>
          <h4 className="logo-text"></h4>
        </div>
        <div className="toggle-icon ms-auto">
          <i className="bi bi-list" />
        </div>
      </div>

      {/* Navigation */}
      <ul className="metismenu" id="menu">

        {/*  <li>
          <a onClick={() => navigateTo("/admin/home")} aria-expanded="true">
            <div className="parent-icon"><i className="bi bi-house-fill" /></div>
            <div className="menu-title">الرئيسية</div>
          </a>
        </li> */}


        <RoleCheck roles={["admin"]} hideIfNoRole={true}>
          <li onClick={() => navigateTo("/admin/supervisor")}>
            <a href="javascript:;">
              <div className="parent-icon"><i className="bi bi-person-check-fill" /></div>
              <div className="menu-title">مشرف مبيعات</div>
            </a>
          </li>

        </RoleCheck>
        
        <RoleCheck roles={["admin","supervisor"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/marketer")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-person-lines-fill" /></div>
            <div className="menu-title">المناديب</div>
          </a>
        </li>
        </RoleCheck>
        
        <RoleCheck roles={["admin"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/user")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-people-fill" /></div>
            <div className="menu-title">المستخدمون</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/address")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-house-door-fill" /></div>
            <div className="menu-title">العناوين</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin","market"]} hideIfNoRole={true}>
        <li onClick={() => navigateTo("/admin/driver")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-person-fill" /></div>
            <div className="menu-title">السائقون</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin","marketer"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/market")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-shop-window" /></div>
            <div className="menu-title">الأسواق</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin","marketer"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/order")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-receipt-cutoff" /></div>
            <div className="menu-title">الطلبات</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin","market"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/cart")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-cart-fill" /></div>
            <div className="menu-title">السلة</div>
          </a>
        </li>
        </RoleCheck>


        <li onClick={() => navigateTo("/admin/orderitem")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-box-fill" /></div>
            <div className="menu-title">عناصر الطلب</div>
          </a>
        </li>

        <RoleCheck roles={["admin","market"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/product")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-box-seam" /></div>
            <div className="menu-title">المنتجات</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin"]} hideIfNoRole={true}>
        <li onClick={() => navigateTo("/admin/offer")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-gift-fill" /></div>
            <div className="menu-title">العروض</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/category")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-tags-fill" /></div>
            <div className="menu-title">الفئات</div>
          </a>
        </li>
        </RoleCheck>
        <RoleCheck roles={["admin"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/field")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-flag-fill" /></div>
            <div className="menu-title">الحقول</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin","market"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/transaction")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-wallet-fill" /></div>
            <div className="menu-title">المعاملات</div>
          </a>
        </li>
        </RoleCheck>

        <RoleCheck roles={["admin"]} hideIfNoRole={true}>

        <li onClick={() => navigateTo("/admin/appconfig")}>
          <a href="javascript:;">
            <div className="parent-icon"><i className="bi bi-gear-fill" /></div>
            <div className="menu-title">إعدادات التطبيق</div>
          </a>
        </li>
        </RoleCheck>

        <li>
          <a onClick={() => {
            localStorage.clear();
            navigateTo("/");
          }} aria-expanded="true">
            <div className="parent-icon"><i className='bx bxs-exit'></i></div>
            <div className="menu-title">تسجيل الخروج</div>
          </a>
        </li>
      </ul>
    </aside>
  );
}