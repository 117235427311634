import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl }from "../../constants";
import { useParams } from 'react-router-dom';

// Field Detail Component
const FieldDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/field/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Field Details</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">id</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">nameAr</label>
                        <input type="text" value={item.nameAr} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">nameEng</label>
                        <input type="text" value={item.nameEng} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">imageUrl</label>
                        <input type="text" value={item.imageUrl} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">status</label>
                        <input type="text" value={item.status} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">createdAt</label>
                        <input type="text" value={item.createdAt} disabled className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FieldDetail;
