import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl }from "../../constants";

// Order Edit Component
const OrderEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ id: '', marketId: '', status: '', addressId: '', userId: '', totalCost: '', tax: '', deliveryCost: '', productsCost: '', driverId: '', payment: '', notes: '', rate: '', createdAt: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl+`api/order/`+id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/order/`+id, item);
        // Redirect or show success message
        navigate(`/admin/order`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">Edit Order</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">id</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">marketId</label>
                        <input type="text" name="marketId" value={item.marketId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">status</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">addressId</label>
                        <input type="text" name="addressId" value={item.addressId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">userId</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">totalCost</label>
                        <input type="text" name="totalCost" value={item.totalCost} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">tax</label>
                        <input type="text" name="tax" value={item.tax} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">deliveryCost</label>
                        <input type="text" name="deliveryCost" value={item.deliveryCost} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">productsCost</label>
                        <input type="text" name="productsCost" value={item.productsCost} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">driverId</label>
                        <input type="text" name="driverId" value={item.driverId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">payment</label>
                        <input type="text" name="payment" value={item.payment} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">notes</label>
                        <input type="text" name="notes" value={item.notes} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">rate</label>
                        <input type="text" name="rate" value={item.rate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">createdAt</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default OrderEdit;
